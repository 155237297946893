<template>
  <b-modal
    :id="`import-campaign-modal`"
    :modal-class="'default-modal'"
    header-class="justify-content-between"
    :hide-footer="true"
    @hidden="hidden"
    @shown="shown"
  >

    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>Importar campanha</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div>
        <form class="default-form" @submit.prevent="">
          <div class="text-center">
            Selecione a qual anunciante você deseja vincular a campanha <strong>{{ campaign?.name }}</strong>.
          </div>
          <div class="form-group row mb-5">
            <div class="col-12">
              <label class="required form-label">Anunciante</label>
              <SelectAdvertiserInput
                v-model="form.advertiser_id"
              />
            </div>
          </div>
          <div class="form-group row mb-5">
            <div class="col-12">
              <label class="required form-label">Orçamento vendido</label>
              <Money
                class="form-control"
                v-model.trim="form.sold_amount"
                v-bind="money"
              />
            </div>
          </div>
          <div class="form-group row mb-5">
            <div class="col-12">
              <label class="required form-label">CPC vendido</label>
              <Money
                class="form-control"
                v-model.trim="form.sold_cpc"
                v-bind="money"
              />
            </div>
          </div>
          <div class="d-flex mb-5">
            <div class="col-lg-6">
              <label class="required form-label">Início da campanha</label>
              <DateTimeInput
                v-model.trim="form.start_date"
                format="dd/MM/yyyy"
                type="date"
                theme="basic"
                class="mx-2"
                placeholder="Início"
              />
            </div>
            <div class="col-lg-6">
              <label class="required form-label">Término da campanha</label>
              <DateTimeInput
                v-model.trim="form.end_date"
                format="dd/MM/yyyy"
                type="date"
                theme="basic"
                class="mx-2"
                placeholder="Término"
              />
            </div>
          </div>

          <div class="mt-5 text-center">
            <Button
              class="btn btn-primary"
              @click="submit"
              :loading="loader"
            >
              Importar
            </Button>
          </div>
        </form>
    </div>
  </b-modal>
</template>

<script>

import { parseErrorResponseToArray } from '@/utils'
import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import NotificationService from '@/modules/notifications/services/notifications-service'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import { Money } from 'v-money'
import SelectAdvertiserInput from '@/components/advertisers/SelectAdvertiserInput'

export default {
  name: 'ImportCampaignModal',
  props: ['campaign', 'platform'],
  components: {
    AlertWraper,
    Button,
    DateTimeInput,
    Money,
    SelectAdvertiserInput
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      form: {
        advertiser_id: '',
        campaign_id: '',
        end_date: '',
        platform: '',
        sold_cpc: 0,
        sold_amount: 0,
        start_date: ''
      },
      loader: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      }
    }
  },
  created () {
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
      this.form.advertiser_id = ''
      this.form.campaign_id = ''
      this.form.sold_amount = 0
      this.form.sold_cpc = 0
    },
    /**
     * On modal shown
    */
    shown () {
      this.form.platform = this.platform
      this.form.campaign_id = this.campaign.id
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true
      this.content = null
      try {
        const form = JSON.parse(JSON.stringify(this.form))
        form.sold_cpc = parseInt(form.sold_cpc * 100)
        form.sold_amount = parseInt(form.sold_amount * 100)

        await CampaignsService.importCampaign(form)

        this.$bvModal.hide('import-campaign-modal')

        const notification = {
          notification: {
            type: 'success',
            content: 'Campanha importada com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }

      this.loader = false
    }
  }
}
</script>
