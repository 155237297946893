<template>
  <div>
    <Select2
      class="default-style"
      :options="advertisersOptions"
      @input="selectAdvertiser"
      placeholder="Buscar anunciante"
      :selectedOption="selectedAdvertiser"
      :filterable="false"
      @search="searchAdvertisers">
    </Select2>
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'

import Select2 from '@/components/common/Select/Select2'

export default {
  name: 'SelectAdvertiserInput',
  props: ['value'],
  components: {
    Select2
  },
  data () {
    return {
      advertisers: [],
      selectedAdvertiser: '',
      throttleSearch: null
    }
  },
  computed: {
    /**
     * Advertiser options
    */
    advertisersOptions () {
      const processedIds = new Set()
      return this.advertisers
        .filter(advertiser => !processedIds.has(advertiser.id) && processedIds.add(advertiser.id))
        .map(advertiser => ({ label: `${advertiser.name}`, code: advertiser.id }))
    }
  },
  methods: {
    /**
     * Get advertisers
     */
    async getAdvertisers (searchTerm, loading) {
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          search: searchTerm,
          page: 1
        })
        this.advertisers = [...this.advertisers, ...advertisers.data]
        loading(false)
      } catch (error) {
        this.$emit('error', error)
      }
    },
    /**
     * Get advertisers for search
     */
    getAdvertisersForSearch (searchTerm, loading) {
      this.advertisers = []
      this.page = 1
      this.getAdvertisers(searchTerm, loading)
    },
    /**
     * Handle select advertiser
     */
    selectAdvertiser (advertiser) {
      this.$emit('input', advertiser ? advertiser.code : '')
    },
    /**
     * Search advertisers
     */
    searchAdvertisers ({ searchTerm, loading }) {
      clearTimeout(this.throttleSearch)
      this.throttleSearch = setTimeout(() => {
        this.getAdvertisersForSearch(searchTerm, loading)
      }, 800)
    }
  }
}
</script>
