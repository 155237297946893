<template>
  <div>
    <MainLayout>
      <template v-slot:toolbar>
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <!--begin::Title-->
          <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Importar campanhas</h1>
          <!--end::Title-->
        </div>
        <!--begin::Actions-->
        <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
          <!--begin::Secondary button-->
          <!--begin::Filter menu-->
          <div class="m-0">
            <!--begin::Menu toggle-->
            <a
              href="#"
              @click.prevent="isFiltersOpen = !isFiltersOpen"
              class="btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
            >
            <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
            <span class="svg-icon svg-icon-6 svg-icon-muted me-1">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
              </svg>
            </span>
            <!--end::Svg Icon-->Filtrar</a>
            <!--end::Menu toggle-->
            <!--begin::Menu 1-->
            <div
              class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
              :class="{
                'show': isFiltersOpen
              }"
              style="position: absolute; top: 40px; right: 0;"
            >
              <!--begin::Header-->
              <div class="px-7 py-5">
                <div class="fs-5 text-dark fw-bold">Opções de Filtro</div>
              </div>
              <!--end::Header-->
              <!--begin::Menu separator-->
              <div class="separator border-gray-200"></div>
              <!--end::Menu separator-->
              <!--begin::Form-->
              <div class="px-7 py-5">
                <!--begin::Input group-->
                <div class="mb-5">
                  <!--begin::Label-->
                  <label class="form-label fw-semibold">Busca:</label>
                  <p style="font-size: 11px;">(ID, nome)</p>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <div>
                    <input type="text" class="form-control form-control-solid" placeholder="" v-model.trim="filters.search"/>
                  </div>
                  <!--end::Input-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="mb-5">
                  <!--begin::Label-->
                  <label class="form-label fw-semibold">Status:</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <div>
                    <select class="form-select" aria-label="Select example" v-model="filters.status">
                      <option value="">Todos</option>
                      <option value="active">Ativa</option>
                      <option value="ended">Encerrada</option>
                      <option value="paused">Pausada</option>
                    </select>
                  </div>
                  <!--end::Input-->
                </div>
                <!--end::Input group-->
                <!--begin::Actions-->
                <div class="d-flex justify-content-end">
                  <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" @click="reset">Resetar</button>
                </div>
                <!--end::Actions-->
              </div>
              <!--end::Form-->
            </div>
            <!--end::Menu 1-->
          </div>
          <!--end::Filter menu-->
          <!--end::Secondary button-->
        </div>
        <!--end::Actions-->
        <!--end::Page title-->
      </template>

      <!-- CONTENT -->
      <!--begin::Products-->
      <div class="card card-flush">
        <!--begin::Card body-->
        <div class="card-body pt-0">
          <template v-if="loader">
            <div class="py-5">
              <Loader size="big" />
            </div>
          </template>
          <template v-else-if="!loader && campaigns.length === 0">
            <p class="text-center pt-5">Nenhuma campanha encontrada</p>
          </template>
          <template v-else>
            <div class="table-responsive">
              <!--begin::Table-->
              <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
                <!--begin::Table head-->
                <thead>
                  <!--begin::Table row-->
                  <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th class="min-w-100px">ID</th>
                    <th class="min-w-100px">Nome</th>
                    <th class="min-w-70px">Status</th>
                    <th class="min-w-70px">Ações</th>
                  </tr>
                  <!--end::Table row-->
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody class="fw-semibold text-gray-600">
                  <!--begin::Table row-->
                  <tr v-for="(campaign, index) in filteredCampaigns" :key="index">
                    <td>
                      <span>
                        {{ campaign.id }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ campaign.name }}
                      </span>
                    </td>
                    <td>
                      <span>{{ campaign.status.name }}</span>
                    </td>
                    <td>
                      <span>
                        <Button
                          class="btn btn-sm btn-primary"
                          @click="openImportModal(campaign)"
                        >
                          <i class="fas fa-file-import px-0"></i>
                        </Button>
                      </span>
                    </td>
                  </tr>
                  <!--end::Table row-->
                </tbody>
                <!--end::Table body-->
              </table>
            </div>
            <ImportCampaignModal
              :campaign="selectedCampaign"
              platform="MGID"
              @hidden="clearSelectedCampaign"
            />
            <!--end::Table-->
            <div class="text-center py-5">
              <Button
                class="btn-primary"
                :loading="loader"
                @click="loadMore"
                v-if="isLoadMoreVisible"
              >
                Carregar mais
              </Button>
            </div>
          </template>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Products-->
      <!-- END CONTENT -->
    </MainLayout>
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'

import Button from '@/components/common/Button/Button'
import ImportCampaignModal from '@/components/campaigns/ImportCampaignModal'
import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'

export default {
  components: {
    Button,
    ImportCampaignModal,
    Loader,
    MainLayout
  },
  metaInfo () {
    return {
      title: 'Importar campanhas'
    }
  },
  data () {
    return {
      campaigns: [],
      filters: {
        search: '',
        status: 'active'
      },
      isFiltersOpen: false,
      isLoadMoreVisible: false,
      loader: false,
      page: 1,
      selectedCampaign: null
    }
  },
  created () {
    this.getCampaigns()
  },
  computed: {
    filteredCampaigns () {
      return this.campaigns.filter(item => {
        let flag = true
        // Filter status
        if (this.filters.status && this.filters.status !== item.status.name) flag = false
        // Filter name or id
        const search = this.filters.search.toLowerCase()
        if (search && (!item.id.toString().includes(search) && !item.name.toLowerCase().includes(search))) flag = false

        return flag
      })
    }
  },
  methods: {
    /**
     * Clear selected campaign
    */
    clearSelectedCampaign () {
      this.selectedCampaign = null
    },
    /**
     * Get list of campaigns
    */
    async getCampaigns () {
      this.loader = true
      try {
        let campaigns = await CampaignsService.getImportableCampaigns({
          platform: 'MGID'
        })
        campaigns = Object.values(campaigns)
        this.campaigns = [...this.campaigns, ...campaigns]
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
    */
    loadMore () {},
    /**
     * Open import modal
    */
    openImportModal (campaign) {
      this.selectedCampaign = campaign
      this.$bvModal.show('import-campaign-modal')
    },
    /**
     * Reset filters
    */
    reset () {
      this.filters.search = ''
      this.filters.status = 'active'
    }
  }
}
</script>
